<template>
  <div class="lesson-video-block">
    <transition name="fade">
      <div class="app-button-layout-wrapper">
        <app-button-layout v-if="!clickedVideo || !enableResume" color="secondary" @click="playVideo">
          <app-button-video-layout>
            {{ buttonLabel }}
          </app-button-video-layout>
        </app-button-layout>
      </div>
    </transition>
    <div class="video-wrapper">
      <video ref="video" controlslist="nodownload" controls :poster="poster" @play="clickedVideo = true">
        <source :src="video" type="video/mp4">
        <track v-if="subtitles"
          :src="subtitles"
          :label="$t('global.language')"
          kind="subtitles"
          srclang="fr"
          default />
      </video>
    </div>
    <slot name="bottom" :is-clicked-video="clickedVideo">
      <div class="bottom" v-if="enableResume">
        <p class="help" v-if="!clickedVideo">
          {{ $t('module1.part3.lesson1.help') }}
        </p>
        <app-button-layout v-else @click="$emit('next-tab')">
          {{ $t('global.navigation.resume') }}
        </app-button-layout>
      </div>
    </slot>
  </div>
</template>

<script>
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
import AppButtonVideoLayout from '@/components/buttons/AppButtonVideoLayout'

export default {
  name: 'LessonVideoBlock',
  components: { AppButtonLayout, AppButtonVideoLayout },
  emits: ['next-tab'],
  props: {
    buttonLabel: {
      type: String,
      required: true
    },
    video: {
      type: String,
      required: true
    },
    poster: {
      type: String,
      required: false,
      default: require('@/assets/default-thumbnail.svg')
    },
    enableResume: {
      type: Boolean,
      required: false,
      default: true
    },
    subtitles: {
      type: String,
      required: false,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      clickedVideo: false
    }
  },
  methods: {
    playVideo () {
      this.clickedVideo = true
      this.$refs.video.play()
    }
  }
}
</script>

<style lang="scss" scoped>
.app-button-layout-wrapper {
  padding: $space-s;
  .app-button-layout {
    margin: 0 auto;
  }
}
.video-wrapper {
  max-width: $training-max-width;
  padding: $space-s;
  margin: 0 auto;
  video {
    width: 100%;
    aspect-ratio: 16/9;
  }
}
.bottom {
  text-align: center;
  margin: $space-ml auto 0 auto;
  max-width: $training-max-width-tablet;
  padding: $space-m;
  .app-button-layout {
    width: 100%;
  }
}
</style>
