<template>
  <step-lesson-layout :title="$t('module3.part1.lesson.title')" :title-annotation="$t('module3.part1.lesson.titleAnnotation')">
    <template v-slot:content>
      <!-- BLOCK 2 -->
      <div class="lesson-block">
        <p class="title">{{ $t('module3.part1.lesson.block2.title') }}</p>
        <lesson-video-block
          video="/videos/oscar-acteur-mpr.mp4"
          subtitles="/subtitles/oscar-acteur-mpr.vtt"
          :button-label="$t('module3.part1.lesson.block2.videoButton')"
          :enable-resume="false"
          :poster="require('@/assets/module3/part1/thumbnail-video-mpr.svg')">
        </lesson-video-block>
      </div>

      <!-- BLOCK 1 -->
      <div class="lesson-block">
        <p class="title">{{ $t('module3.part1.lesson.block1.title') }}</p>
        <div class="groups">
          <div class="group" v-for="group in groups" :key="group.name">
            <p class="title">{{ group.title }}</p>
            <div class="actors" v-if="group.actors">
              <div class="actor" v-for="actor in group.actors" :key="actor.name" :class="{ big: actor.big }">
                <p class="label" v-if="actor.label && actor.big">{{ actor.label }}</p>
                <div class="content">
                  <p class="label" v-if="actor.label && !actor.big">{{ actor.label }}</p>
                  <p class="text" v-html="actor.content"></p>
                  <img v-if="actor.big" :src="actor.image" :alt="actor.name">
                </div>
                <div class="content" v-if="actor.content2">
                  <p class="text" v-html="actor.content2"></p>
                </div>
                <img v-if="!actor.big" :src="actor.image" :alt="actor.name">
              </div>
            </div>
            <div v-else class="actor big">
              <p class="label" v-if="group.label">{{ group.label }}</p>
              <div class="content">
                <p class="text" v-html="group.content"></p>
                <img :src="group.image" :alt="group.name">
              </div>
              <p v-if="group.content2" class="text" v-html="group.content2"></p>
            </div>
          </div>
        </div>
      </div>

      <!-- END BLOCK -->
      <div class="app-decoration-block end-block">
        <p class="decoration-block-content">
          {{ $t('training.global.partFinished') }}
        </p>
        <app-button-layout @click="goToNextStep">
          {{ $t('global.navigation.resume') }}
        </app-button-layout>
        <app-button-layout color="alternate-secondary" @click="resumeLater">
          {{ $t('global.navigation.resumeLater') }}
        </app-button-layout>
      </div>
    </template>
  </step-lesson-layout>
</template>

<script>
import StepLessonLayout from '@/components/training/StepLessonLayout'
import LessonVideoBlock from '@/components/training/LessonVideoBlock'
import useTrainingUtils from '@/utils/useTrainingUtils'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'

export default {
  name: 'Module3Part1Lesson',
  components: { LessonVideoBlock, StepLessonLayout, AppButtonLayout },
  setup (props, context) {
    const { resumeLater, goToNextStep } = useTrainingUtils()
    return { resumeLater, goToNextStep }
  },
  data () {
    return {
      groups: [
        {
          name: 'financeur',
          title: this.$t('module3.part1.lesson.block1.groups.group1.title'),
          actors: [
            {
              name: 'etat',
              label: this.$t('module3.part1.lesson.block1.groups.group1.actors.actor1.name'),
              content: this.$t('module3.part1.lesson.block1.groups.group1.actors.actor1.content'),
              image: require('@/assets/module3/part1/etat.svg')
            },
            {
              name: 'anah',
              label: this.$t('module3.part1.lesson.block1.groups.group1.actors.actor2.name'),
              content: this.$t('module3.part1.lesson.block1.groups.group1.actors.actor2.content'),
              image: require('@/assets/module3/part1/anah.svg')
            }
          ]
        },
        {
          name: 'beneficiaire',
          title: this.$t('module3.part1.lesson.block1.groups.group2.title'),
          actors: [
            {
              name: 'proprio',
              content: this.$t('module3.part1.lesson.block1.groups.group2.actors.actor1.content'),
              image: require('@/assets/module3/part1/occupants.svg')
            },
            {
              name: 'bailleur',
              content: this.$t('module3.part1.lesson.block1.groups.group2.actors.actor2.content'),
              image: require('@/assets/module3/part1/bailleurs.svg')
            },
            {
              name: 'copro',
              content: this.$t('module3.part1.lesson.block1.groups.group2.actors.actor3.content'),
              image: require('@/assets/module3/part1/copro.svg')
            }
          ]
        },
        {
          name: 'pros',
          title: this.$t('module3.part1.lesson.block1.groups.group3.title'),
          content: this.$t('module3.part1.lesson.block1.groups.group3.content'),
          image: require('@/assets/module3/part1/pros.svg')
        },
        {
          name: 'conseillers',
          title: this.$t('module3.part1.lesson.block1.groups.group4.title'),
          label: this.$t('module3.part1.lesson.block1.groups.group4.name'),
          content: this.$t('module3.part1.lesson.block1.groups.group4.content'),
          image: require('@/assets/module3/part1/france-renov.svg')
        },
        {
          name: 'accompagnateurs',
          title: this.$t('module3.part1.lesson.block1.groups.group5.title'),
          actors: [
            {
              name: 'accompagnateur',
              label: this.$t('module3.part1.lesson.block1.groups.group5.actors.actor2.name'),
              content: this.$t('module3.part1.lesson.block1.groups.group5.actors.actor2.content'),
              content2: this.$t('module3.part1.lesson.block1.groups.group5.actors.actor2.content2'),
              image: require('@/assets/module3/part1/accompagnater-renov.svg'),
              big: true
            },
            {
              name: 'amo',
              label: this.$t('module3.part1.lesson.block1.groups.group5.actors.actor1.name'),
              content: this.$t('module3.part1.lesson.block1.groups.group5.actors.actor1.content'),
              image: require('@/assets/module3/part1/amo.svg'),
              big: true
            }
          ]
        },
        {
          name: 'mandataire',
          title: this.$t('module3.part1.lesson.block1.groups.group6.title'),
          content: this.$t('module3.part1.lesson.block1.groups.group6.content'),
          content2: this.$t('module3.part1.lesson.block1.groups.group6.content2'),
          image: require('@/assets/module3/part1/mandataire.svg'),
          big: true
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.lesson-block + .lesson-block {
  margin-top: $space-l;
}
.lesson-block {
  padding: 0 $space-s;
  .title {
    color: $c-secondary;
    font-size: $fz-xll;
    font-weight: $fw-l;
    text-align: center;
    text-transform: uppercase;
  }
  .groups {
    margin-top: $space-s;
    display: grid;
    gap: $space-s;
    .group {
      background-color: #fff;
      border-radius: $radius;
      padding: $space-sm;
      > .title {
        font-size: $fz-xl;
        margin-bottom: $space-sm;
      }
      .actors {
        .actor + .actor {
          margin-top: $space-sm;
          padding-top: $space-sm;
          border-top: 1px solid $c-border;
        }
      }
      .actor {
        font-size: $fz-m;
        font-weight: $fw-m;
        > .label {
          margin-bottom: $space-s;
        }
        .label {
          font-weight: $fw-l;
        }
        &:not(.big) {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        &.big {
          .text {
            font-family: $ff-text;
            font-size: $fz-sm;
            font-weight: $fw-m;
            line-height: $ln-m;
          }
          .content {
            display: flex;
            justify-content: space-between;
            img {
              margin-top: $space-xs;
              margin-left: $space-m;
            }
          }
          .content + .content {
            margin-top: $space-m;
          }
          .content + .text {
            margin-top: $space-m;
          }
        }
      }
    }
  }
}

@media (min-width: $bp-tablet) {
  .lesson-block {
    .groups {
      grid-template-columns: repeat(2, 1fr);
      .group {
        .actor {
          &.big {
            .content {
              img {
                margin-right: $space-sm;
              }
            }
          }
        }
      }
    }
  }
}
</style>
