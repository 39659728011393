<template>
  <div class="app-button-video-layout">
    <p>
      <slot></slot>
    </p>
    <div class="icon"></div>
  </div>
</template>

<script>
export default {
  name: 'AppButtonVideoLayout'
}
</script>

<style lang="scss" scoped>
.app-button-video-layout {
  display: flex;
  align-items: center;
  p {
    text-transform: none;
    margin-right: $space-l;
    margin-left: $space-s;
    font-size: $fz-s;
    text-align: left;
  }
  .icon {
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    &:after {
      content: '';
      position: relative;
      left: 1px;
      border-top : 7px solid transparent;
      border-bottom : 7px solid transparent;
      border-left : 9px solid $c-secondary;
    }
  }
}
</style>
